.user-container{
    background:var(--white-background);
    border-radius: 8px 8px 0px 0px;
    }
    
    .user-container .title{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color:var(--black-text)
    }
    .user-container .user-container-card{
        background:var(--white-background);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        // width:90%;
    }
    .card-detail-container .card-container-img{
        width: 250px;  
        height: 200px;
    }
    
    .card-detail-container .card-container-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 13px;
      }
      .user-container-card .property-details{
    
        width:60%;
      }
      .user-container-card .property-details .property-name{
    
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color:var(--dark-shadow-tex)
      }
      .user-container-card .property-details .property-details-lable{
    
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color:var(--dark-shadow-tex)
      }
      .user-container-card .property-details .property-details-value{
    
        font-style: normal;
      
        font-size: 16px;
        color:var(--dark-shadow-tex)
      }
      .user-container-card .property-details .property-location{
    
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color:var(--dark-shadow-tex)
      }
      .user-container .response-container-card{
        background:var(--white-background);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        width:90%;
    }
    .response-detail-container .response-container-img{
        width: 139px;  
        height: 100px;
    }
    
    .response-detail-container .response-container-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      .response-detail-container .response-details .response-name{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color:var(--dark-shadow-tex)
    
      }
      .response-container-card .response-details{
    
        width:90%;
      }
      
    
    
      
    @media only screen and (max-width: 1200px) {
        
      }
      
      @media only screen and (max-width: 900px) {
        
        .response-container-card .response-detail-container{
            flex-direction: column;
        }
        
        .user-container-card .property-details .w-50{
            width:100% !important;
        }
        .response-container-card .response-details{
    
            width:100%;
          }
        .response-detail-container .response-container-img{
            margin: 0 auto;
        }
      }
      @media only screen and (max-width: 600px) {
        
      }
      @media (max-width: 768px) {
        .wrapper{
          flex-direction: column;
        }
        .card-detail-container .card-container-img{
          width: 100%;
        }
        .user-container-card .property-details{
          width: 100%;
        }
        .customStyle{
          display: flex;
    padding: 15px;
    justify-content: space-between;
    width: 50% !important;
        }
      }
      
      @media only screen and (max-width: 600px) {
        
      }
      .widthDiv{
        min-width: 70% !important;
      }
      @media (max-width:1000px){
      }
      .customStyle{
        width: 15%;
      }
      .textColor{
        color: #034168 !important;
      }