.modal-header {
  border: 0;
  .modal-title {
    font-weight: 700;
  }
 
}


.customer__change {
  max-height: 50vh;
    overflow-y: auto;
  .customer-list__name {
    font-weight: 700;
    font-size: 16px;
    color: #3E4958;
  }

  .customer-list__role {
    font-weight: 400;
    font-size: 14px;
    color: #3E4958;
  }
 

  .customer__change>div:first-child {
    border-top: 0.5px solid #D9D9D9;
    border-bottom: 0.5px solid #D9D9D9;
  }

  .customer__change_rm {
    border-bottom: 0.5px solid #D9D9D9;
  }

  .change_rm__name {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }

  .customer__change_rm .change_rm__action {
    font-weight: 300;
    font-size: 14px;
    color: #034168;
  }

  .modal-body>div:first-child {
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
  }
}
