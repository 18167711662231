// .navBar-Container{
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 30px;
// }
.navBar-Container .navNames{
    font-size: 24px;
    color: #000000;
    font-weight: 500;
    // text-decoration-line: underline;
}
// .navBar-Container .navNames:last-child{
//     color: #000000;
// }
.navBar-Container .right-container{
    display: flex;
}
.nav-btn-container{
    display: flex;
}
.navBar-Container .chevron{
    
}
@media screen and (max-width: 800px) {
    .navBar-Container{
        display: block;
        /* padding: 0px 40px; */
    }
    .navBar-Container .nav-path{
        /* justify-content: center; */
        margin-bottom: 10px;
    }
    .navBar-Container .right-container{
        justify-content: space-between;
    }
    .navBar-Container .navNames{
        font-size:14px;
        width: 100%;
    }
}
@media screen and (max-width: 700px) {
    .navBar-Container .right-container{
        display: block;
        text-align: center;
    } 

    .navBar-Container .right-container .search-bar{
        margin-bottom: 10px;
    }
    .nav-btn-container{
        display: flex;
        justify-content: center;
    }
   
}

@media screen and (max-width: 600px) {
    .navBar-Container .right-container .nav-btn-container .btn-primary{
        padding: 10px 10px !important;
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0px;
    }
}
@media screen and (max-width: 420px) {
    .navBar-Container{
        margin-bottom: 0px;
    }
    .navBar-Container .right-container .nav-btn-container .btn-primary{
        /* padding: 10px 10px !important; */
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0px;
    }
    .nav-btn-container{
        display: block;
        justify-content: center;
    }
}