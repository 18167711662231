.pageNotFound {
    width: 450px;
    max-width: 100%;
    margin: 0px auto;
    background: none;
    flex-direction: column;
  }
  .pageNotFound.error {
    width: 550px;
  }
  .pageNotFound img {
    max-width: 80%;
    margin: 0 auto;
    display: block;
  }
  .pageNotFound h2 {
    font-size: 40px;
    margin-bottom: 35px;
  }
  .pageNotFound ul {
    list-style-type: disc;
  }
  
  /* sign in */
  .centerForm {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - var(--topspace));
    /* min-height: 550px */
    padding: 50px 0;
    /* background: url("./assets/bg.png"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #F6F6F6;
  }
  .centerForm form {
    width: 100%;
    max-width: 500px;
    padding: 50px 25px;
    border: 2px solid rgba(21, 120, 162, 0.3);
    background-color: #fff;
    border-radius: var(--border-radius-default);
  }
  .centerForm form input {
    /* max-width: 80%;
    margin: 0 auto; */
    height: var(--inputHeight);
  }
  .centerForm form .form-group {
    max-width: 80%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 4px;
  }
  .centerForm form .invalid-feedback {
    position: absolute;
    top: 50px;
    margin-top: 0;
  }