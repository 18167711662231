.btn-outline-color-primary-google-map {
  background-color: white !important;
  color: rgba(21, 25, 32, 0.5) !important;
  border: 1px solid #015169 !important;
  border-radius: 0.375rem !important;
  font-weight: 400 !important;
  font-size: 1rem;
  &:hover {
    color: #ffffff !important;
    background: #015169 !important;
  }
}

// .btn-outline-color-primary-google-map :hover {
//   background-color: #015169 !important;
//   color: white !important;
// }

.map_buttons {
  padding: 8px !important;
}
