

.textArea-height{

    height: 130px;
}
.range-input{
   
      .row{

      }
}
.postDemand-container{
  .postDemand-form-container{
    background: #FFFFFF;
    .title{
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 64px;
      .noteText {
        font-size: 15px;
        margin-top: -30px;
      }
    }
  .form{
    max-width: 900px;
    margin: 20px auto;
    background: #FFFFFF;
  
   
  }
  
  
  }
  
  }
.post-new-demand {
    position: relative;
    .hidebody {
      position: absolute;
      height: calc(100% - 20px);
      width: 100%;
      background: transparent;
      top: 0;
      left: 0;
      z-index: 10;
    }

    .multi-search-item {
        position: relative;
       z-index:10;
    }
   
  }
  @media (max-width:900px){
    .range-input{
   
        .row{
            .col{
                width:100% !important;
 
            }
        }
  }

}