

.custom-multi-select-list {
    background-color:  rgba(123, 151, 206, 0.68);
    border-radius: 5px;
    color: #fff;
    padding: 5px;
    white-space: nowrap;
    // margin-right: 5px;
    display: flex;
    justify-content: space-between;
    
  }
  .multi-select-content{
    .invalid-error{
      width: 100%;
      margin-top: 0.25rem;
      /* font-size: 0.875em; */
      color: #dc3545;
      font-size: 11px
    }
    .invalid-control{
      border-color: #dc3545 !important;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: #ced4da;
      border-radius: 0.375rem;
      border-style: solid;
      border-width: 1px;
      cursor: default;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      min-height: 38px;
      outline: none;
      position: relative;
      -webkit-transition: none;
      transition: none;
      box-sizing: border-box;
      padding: 1px 5px;
      width: 100%;
      background: #ffff;
    }
  }
  
  .custom-multi-select-list .close-icon {
    background: transparent;
    border: none;
   
  }
  .customInvalid{
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545 !important;
  }
  .multi-select-content {
    .css-olqui2-singleValue{
      color:#555555 !important;
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
  