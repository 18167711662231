.btn {
  padding: 10px 1.5rem !important;
}
.marginTopReport {
  // margin-top: 2.5%;
  height: 40px;
  width: 25%;
  white-space: nowrap;
}
.marginTopPiechart {
}
.mtop {
  margin-top: 14px;
}
