@import '../../../sassStyles/mixin';
.subscription-card {
    .row {
        row-gap: 10px;
        column-gap: 20px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        padding: 15px;

        .subscription-detail-columns {
            width: 48%;
            display: flex;
            border-radius: 6px;
            padding: 10px 20px;
            background: rgba(127, 162, 184, 0.16);
            display: flex;
            justify-content: space-between;

            p {
                margin-bottom: 0;
            }

            .subscription-key {
                font-size: 16px;
                color: #000000;
                font-weight: 700;
                width: 70%;
            }

            .subscription-value {
                font-size: 16px;
                color: #000000;
                width: 30%;
                text-align: left;
            }
        }
    }
}
.trancateString{
    @include truncate-line-vertical(3);
}
.demand-card{
    background-color: #FFFFFF;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
    border-radius: 8px;
    position: relative;
    
    .ribbon-wrapper {
      position: absolute;
      top: -10px;
      left: -10px;
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;


  }

  .ribbon-wrapper::before {
      content: "Featured";
      position: absolute;
      width: 146%;
      height: 28px;
      background: #ED3C3F;
      transform: rotate(-45deg) translateY(-14px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      color: #fff;
      letter-spacing: 0.1em;
      box-shadow: 0.5px 5px rgb(0 0 0 / 6%);
  }

  .ribbon-wrapper::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 10px;
      height: 10px;
      background: #c1474a;
      z-index: -1;
      box-shadow: 110px -110px #c1474a;
  }
  
}