.reportHeader {
  height: 80px;
  width: 210px;
  padding: 15px;
  background-color: #7fa2b83d;
  border-radius: 5px;
  margin: 5px 10px;
  p {
    margin: 0px;
    display: flex;
    justify-content: center;
  }
  span {
    display: flex;
    justify-content: center;
    padding: 5px;
  }
}
.reportWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.lineWrapper {
  height: fit-content;
  background-color: #ffffff;
  border-radius: 5px;
  // margin: 15px;
}
.lineHeadercontailer {
  height: 10%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.activeUserColor {
  color: #7fa2b8;
  font-weight: 500;
  font-size: 24px;
}
.activeUserButton,.timeSpentActiveUserButton {
  display: flex;
  height: 40px;
  width: 60%;
  margin-top: 20px;
  // justify-content: space-between;
  justify-content: flex-end;

  .react-datepicker-wrapper {
    width: 30% !important;
    input {
      height: 40px;
      width: 100%;
      // margin-top: 8%;
      border-radius: 5px;
      border: 1px solid #ced4da;
    }
  }
}
.timeSpentHead{
  display: flex;
  padding: 3px;
  justify-content: space-between;
}
.timeSpentActiveUserButton{
margin-top: 0px;
justify-content: right;
width: auto;
button{
  width: max-content;
}
}
.piechartWrapContainer {
  position: relative;
  .recharts-responsive-container {
    width: 100% !important;
    height: 400px !important;
  }
}
.RegistrationWrapper, .visitorsWrapper{
  margin-top: 20px;
}

.graphWrapper {
  display: flex;
  margin-top: 10px;
  p {
    width: 5%;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    align-self: center;
    transform: scale(-1);
  }
  .recharts-responsive-container {
    width: 90% !important;
    height: 400px !important;
  }
}
.reportWrapper_1 {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.pieWrapper {
  display: grid;
  gap: 20px;
  margin-top: 20px;
  grid-template-columns: repeat(2, 1fr);
}
.lineWrapper_pieChart {
  height: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
}
.marginTopBlock {
  display: flex;
  flex-direction: column-reverse;
  height: 135px;
}
.marginTopPieButton {
  width: 100%;
  height: 40px;
}
.hideHeaderDate {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker-year-header {
    display: none !important;
  }
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    display: none !important;
  }
}
.hideHeaderDate_1 {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker-year-header {
    display: none !important;
  }
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    display: none !important;
  }
  input {
    margin-top: 6% !important;
  }
}

@media (max-width: 1000px) {
  .lineHeadercontailer {
    display: block !important;
  }
  .activeUserButton {
    width: 100%;
  }
  .recharts-responsive-container {
    margin-top: 30px;
  }
}
@media (max-width: 1000px) {
  .lineWrapper {
    .btn {
      padding: 10px 0.5rem !important;
    }
  }
  .pieWrapper {
    display: block !important;
  }
}
.bar_1 {
  input {
    margin-top: 6% !important;
  }
}
.subscribed {
  display: flex;
  width: 50%;
}
.selectCustom {
  width: 90%;
}
.sel_wrapper {
  width: 70%;
}

@media (max-width: 1000px) {
  .subscribed {
    width: 100%;
  }
  .sel_wrapper {
    width: 100%;
  }
  .marginTopPieButton {
    width: 80%;
  }
}
.piechartWrapContainer {
  .custom-counts {
    // position: absolute;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  .custom-boxColor {
    background-color: #23597b;
    height: 15px;
    width: 15px;
    align-self: center;
  }
  .cust-text {
    color: #23597b;
  }
}
.graphMonth {
  display: flex;
  justify-content: center;
}
.pieChartCss{
  width: 100%;
  justify-content:center;
  height: fit-content;
}
.piechartSelect{
  margin-top:1.1% !important;
}
.pieChartMonthly{
  max-width: 165px;
}
.pieChartCss{
  .react-datepicker-wrapper{
    width: 35% !important;
  }
}
.noData{
  margin: 10px 30px;
}
.pieChartCss{
  .react-datepicker-wrapper{
    input {
      height: 40px;
      width: 100%;
      margin-top: 3%;
      border-radius: 5px;
      border: 1px solid #ced4da;
    }
  }
}