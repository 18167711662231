.subscription-container .btn-container{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}

.edit-btn{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}
.editTable-container{
    height: 100%;
    max-height: 50vh;
    min-height: 500px;
    padding: 0px 100px;
    overflow: scroll ;
}


.editTable-container .editTable-content{
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

/* ----Table Head style starts---- */

.editTable-head{

}
.editTable-rowHead{

}
.editTable-colHead{
    width: 27%;
    text-align: center;
    color: #ffffff !important;
    background-color: var(--primary);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    padding: 1rem 1.5rem;
}
.editTable-colHead:first-child{
    width: 50% !important;
    text-align: left !important;
}
/* .editTable-rowHead .editTable-colHead:first-child{
    width: 25% !important;
    text-align: left !important;
} */
.editTable-rowHead .editTable-colHead{
    width: 14%;
}

/* ----Table Head style Ends---- */
/* ---- */
/* ----Table Body Style Starts---- */

.editTable-rowBodyHeading{
    color: #ffffff !important;
    background-color: var(--primary);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    /* padding: 23px; */
    width: 43%;
    padding-left: 26px;
    height: 70px;
    text-align: left;
}
.label-button{
    padding: 6px 11px;
    border: 1px solid black;
    width: 140px;
    border-radius: 11px;
    margin: auto;
}
.editTable-rowBodyValues{
    height: 70px;
    padding: 12px;
    width: 27%;
    border-bottom: 1px solid #7FA2B8;
}
.editTable-rowBodySelect{
    width: 100%;
    height: 40px;
    border: 1px solid #b1b3b3;
    border-radius: 8px;
    padding: 8px;
}
.editTable-amountText{
    text-align: center;
    color: #555555;
    font-weight: 400;
    font-size: 15px;
}
.editTable-rowBodyEnterpriseValues{
    padding: 10px;
    border-bottom: 1px solid #7FA2B8;
}
/* ----Table Body Style Ends---- */


.dataTable-container{
    height: 100%;
    max-height: 50vh;
    min-height: 500px;
    padding: 0px 100px;
    overflow: scroll ;
}

.dataTable-content{
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.subscription-container{
    padding: 0px 30px;
    .table .tableBody tr{
        border-bottom: none !important;
    }
    .table .tableBody td{
        border-bottom: 1px solid #E2E2E2 !important;
    }
}

.subscription-container .benefits-subscription{
    background-color: #015169;
    color: #ffffff;
    text-align: left;
    padding-left: 20px!important;
}

/* ----Table Head style starts---- */

.dataTable-head{

}
.dataTable-rowHead{

}
.dataTable-colHead{
    text-align: center;
    color: #ffffff !important;
    background-color: var(--primary) !important;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    padding: 1rem 1.5rem;
}
.dataTable-colHead:first-child{
    width: 50% !important;
    text-align: left !important;
}
/* .dataTable-rowHead .dataTable-colHead:first-child{
    width: 25% !important;
    text-align: left !important;
} */
.dataTable-rowHead .dataTable-colHead{
    width: 14%;
}

/* ----Table Head style Ends---- */
/* ---- */
/* ----Table Body Style Starts---- */
.dataTable-Body{

}

.dataTable-rowBody{

}
.dataTable-rowBodyHeading{
    color: #ffffff !important;
    background-color: var(--primary)!important;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    padding-left: 26px;
    height: 70px;
    text-align: left;
    /* width: 25%; */
}
.dataTable-rowBodyValues{
    height: 70px;
    padding: 10px;
    /* width: 27%; */
    border-bottom: 1px solid #7FA2B8;
}
.dataTable-rowBodySelect{
    width: 100%;
    height: 40px;
    border: 1px solid #b1b3b3;
    border-radius: 8px;
    padding: 8px;
}
.dataTable-amountText{
    text-align: center;
    color: #555555;
    font-weight: 400;
    font-size: 15px;
}
.dataTable-rowBodyEnterpriseValues{
    padding: 10px;
    border-bottom: 1px solid #7FA2B8;
}
/* ----Table Body Style Ends---- */
