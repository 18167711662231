.bg-light-card {
  background-color: var(--dashBoard-card-bg);
}

.demand-detail-view {
  .card-container {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    background-color: #ffffff;
  }
}
.column-data{
  width: 48%;
    display: flex;
    border-radius: 6px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

/* .brand-details{
  border-right:1px solid #DCE0EA;
} */
.brand-details ul li {
  font-style: normal;
  color: #3e4958;
  font-size: 16px;
}

.brand-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: var(--black-text);
}

// .demand-detail-container {
//   border-right: 1px solid #DCE0EA;
// }

.demand-detail-container .detail-container-img {
  min-width: 100px;
  max-width: 200px;
  max-height: 335px;
}

.demand-detail-container .detail-container-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
}

.primary-details {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 5px;
  // gap:5px;
}



.primary-details .primary-details_cardText {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.primary-details .primary-details_cardSubtext {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  overflow-wrap: "anywhere";
}

.demand-detail-lable {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  border-radius: 6px;
}

.demand-detail-lable-left {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 6px;
}

.save-btn .btn {
  display: flex;
  background-color: #ffffff;
  color: var(--dark-shadow-text);
}

.save-btn .btn:hover {
  display: flex;
  background-color: #ffffff;
  color: var(--dark-shadow-text);
}

.save-btn .btn:before {
  content: url("../../assets/images/saveAS.png");
  margin-right: 10px;
  display: inline-flex;
  padding: 3px;
  height: 10px;
  line-height: 1;
  position: relative;
}

.for-rent {
  padding: 3px 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border: 1px solid var(--border-blue);
  border-radius: 18px;
  color: var(--dark-shadow-tex);
}

// .detailView-footer-container{
//     box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.07);

// }
.detailView-footer-container {
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 25px;
  background-color: #FFF;
  text-align: center;
  margin-bottom: -20px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footer-left {
    text-align: left;
    p {
      text-align: left;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      margin: 0;
      color: var(--dark-shadow-text);
    }

    .companyName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: var(--gray-text);
    }
  }
 

  
}

/* ///////////////////----------------------------------------------------media qureies------------------------/// */
@media only screen and (max-width: 1200px) {
  .primary-details {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 5px;
    // gap:5px;
  }
  

  .card-container .row {
    flex-direction: column;
    width: 100%;
  }

  .card-container .row .col-md-6 {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .primary-details {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 5px;
    // gap:5px;
  }
  .demand-col .row {
    flex-direction: column;
  }

  .demand-detail-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .primary-details .primary-details_cardText {
    font-size: 10px;
  }

  .primary-details .primary-details_cardSubtext {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
  }

  .detailView-footer-btn {
    flex-direction: column;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
 
  .primary-details .primary-details_card {
    width: 100%;
  }

  .detailView-footer-btn {

    text-align: center !important;
  }
}