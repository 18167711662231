.dynamic-form{
    max-height: 250px;
    overflow-y: auto;
}
.location-container{

    .manageWidthSearch{
        width:75%;
    }
    @media (max-width:500px){
        .manageWidthSearch{
            width:100%;
        }
.filter-container{
    flex-direction: column;
    .widWrapper{
        width:100%;
    }
}
        
    }
}