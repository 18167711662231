.relevant-demand-container{
  border-radius: 5px;

  background-color: #ffffff;
 
}



.existing-property-box {
  .row {
    height: 100%;
  }
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  .content {
    margin-top: 0;
    .profile-image{
     width:50px;
     height: 50px;
      img{
        width:100%;
        height: 100%;
        object-fit: cover;
      }

    }
    .image {
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 6px;
      overflow: hidden;
    }
    .inner-content {
      h5 {
        color: var(--dark-shadow-text);
        margin-bottom: 2px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        p {
          width: 50%;
          margin-bottom: 7px;
          span {
            font-weight: 700;
            color: var(--dark-shadow-text);
          }
        }
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: flex-end;
    div {
      display: flex;
    }
    h4 {
      color: var(--dark-shadow-text);
      font-weight: 700;
    }
  }
  .colors {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    h4 {
      
      width: 100%;
      margin-bottom: 0;
      line-height: 20px;
      font-weight: 700;
      &.green {
        color: #3FA674;
      }
      &.red {
        color: #A6833F;
      }
      span {
        font-size: 12px;
        display: block;
        font-weight: 400;
      }
    }
  }
}

.demand-detail-view{

}
.details-lable {
  color: #ffffff;
  text-align: center;
  padding: 3px 20px;
  background: #c5aa46;
  border-radius: 18px;
}

.demand-card-container-relevant {
  grid-template-columns: repeat(2, 1fr) !important;
  display: grid;
  gap: 20px;
}
@media (max-width: 500px) {
  .demand-card-container-relevant {
    grid-template-columns: repeat(1, 1fr) !important;
  
  }
}




