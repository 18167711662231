.BannerWrapper {
  width: 100%;
  height: 45vh;
}
.bannerImage {
  padding: 10px;
  width: 100%;
  height: 100%;
}
.bannerImageUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.dropZoneOverlay,
.FileUpload {
  width: 283px;
  height: 71px;
}

.dropZoneOverlay {
  border: dotted 1px;
  font-family: cursive;
  color: #7066fb;
  position: absolute;
  top: 0px;
  text-align: center;
}

.FileUpload {
  opacity: 0;
  position: relative;
  z-index: 1;
}
.bannertext {
  position: absolute;
}

.brandlistWrapper {
  max-height: 60vh;
  overflow: scroll;
}
.ButtonWrapper {
  display: flex;
  /* width: 60%; */
  padding: 10px;
  justify-content: flex-end;
  position: relative;
}
.contentview-responsive {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
}
.brandsWrapper {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  // grid-column-gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  gap: 20px;
}

@media (max-width: 550px) {
  .brandsWrapper {
    // display: grid;
    grid-template-columns: auto !important;
  }
}
@media (max-width: 1100px) {
  .contentview-responsive {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 790px) {
  .contentview-responsive {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 650px) {
  .contentview-responsive {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.content-Card {
  padding: 10px;
}
.imageBlog {
  // border-color: rgb(145, 82, 0);
  border: 1px solid #ced4da;
  width: 100%;
  border-radius: 5px;
}
.testmonials {
  height: 20vh !important;
  width: 50% !important;
}
@media (max-width: 1000px) {
  .testmonials {
    height: 20vh !important;
    width: 50% !important;
  }
}
.testmonialsSpan {
  max-height: 100px !important;
  position: relative;
  // white-space: nowrap;
  // width: 420px;
  word-break: break-all;
  font-size: 16px;
  overflow: hidden;
  // text-overflow: ellipsis;
}
.blogDetails {
  width: 100% !important;
  height: 30vh !important;
}
.blog {
  display: block !important;
  max-width: 100%;
}
.readmore {
  width: fit-content;
  position: absolute;
  // right: 10%;
}
.readMoreLInk {
}
// .modalWrapperDiv {
//   display: flex;
//   flex-wrap: wrap;
// }
.testmonialsWrap {
  max-width: 100%;
}
@media (max-width: 1000px) {
  .testmonialsWrap {
    max-width: 100%;
  }
}

.testmo_wrapper {
  padding: 0px !important;
}
.blog_cards {
  width: 100%;
  height: auto;
}
.editWrapper {
  display: flex;
  justify-content: space-evenly;
  // width: 90%;
}
.content_fullcards {
  background-color: #fff;
  padding: 30px;
}
@media (max-width: 768px) {
  .content_fullcards {
    background-color: #fff;
    padding: 10px !important;
  }
  .buttonDivision {
    display: flex;
  }
}
.buttonWrap_demand {
  display: flex;
  flex-direction: column-reverse;
}
.deleteButton_demand {
  align-self: center;
}

.tableHeader {
  background-color: #015169 !important;
  color: #fff !important;
}
.contentManagementText {
  font-size: 24px !important;
  line-height: 64px;
}
.customCards {
  // width: 70%;
  height: 178px;
}
.content-height {
  height: 160px !important;
}
.brandFont {
  font-size: 20px !important;
}
.customChangesProperty {
  // max-width: 70%;
  word-break: break-all;
}
.customFeature {
  height: fit-content;
}
.fontButton {
  font-size: 12px !important;
}
.iconsdivision {
  position: absolute;
  right: 20px;
}
.withIcon {
  position: relative;
}
.edit {
  color: black !important;
}
.edit,
.delete {
  cursor: pointer;
}
.littleUp {
  // top: 5px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  height: 80%;
  justify-content: center;
  width: 85%;
  opacity: 0;
}
.postedColor {
  color: #034168 !important;
}
.littleUp:hover {
  opacity: 1;
  // background-color: rgba(255, 255, 255, 0.4);

  .edit,
  .delete {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 550px) {
  .mobileFlexWrapper {
    flex-direction: column;
  }
  .buttonDivision {
    justify-content: flex-end;
    align-self: flex-end;
    width: 150px !important;
  }
  .mobileButtonSide {
    align-self: flex-end;
    width: 150px !important;
  }
}
.styling {
  padding: 0px !important;
}
.slider-Wrapper {
  height: 40vh;
  box-shadow: 0px 2px 16px rgb(60 62 74 / 30%);
  border-radius: 8px;

  .slick-track {
    height: 40vh !important;
  }
  .testmonialsWrap {
    height: 40vh !important;
  }
  .styling {
    height: 35vh !important;

    .sliderImage {
      height: 40vh !important;

      img {
        height: 35vh !important;
        width: 50vh !important;
      }
    }
  }
}

.banner-WithIcon {
  position: relative;
}
.editBanner {
  position: absolute;
  top: 10px;
  right: 5px;
  width: 5%;
  // bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.fileUplpoad {
  opacity: 0;
  position: relative;
  z-index: 1;
}
.imageSpan {
  word-break: break-all;
}
.testWrapper {
  position: absolute;
  right: 25px;
  top:10px;
}
.containWrapper {
  max-height: 400px;
  // overflow: scroll;
  // width: 100%!important;
}
.togContent {
  display: flex;
  justify-content: space-between;
}
.contentToggle {
  display: flex;
  flex-direction: column;
}
.mobileButtonSide,
.buttonDivision {
  display: flex;
  height: fit-content;
  // width: 12%;
  justify-content: space-between;
}
.top-brand-card {
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(60, 62, 74, 0.08);
  border-radius: 6px;
  padding: 1rem 0.5rem;

  .brand-card-img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .brand-card-title {
    color: #000000;
  }
}
.customPosition {
  margin: 10px;
  gap: 20px;
}
.demand-card {
  background-color: #ffffff;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
  border-radius: 8px;
}
.featuredemandImage {
  width: 90px !important;
  height: 83px !important;
  margin-right: 7px;
}
.propertyViewWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
.propertyImageView {
  // height:200px !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 6px;
}
.propertyWrapImages {
  max-width: 160px;
}
.wrappingContent {
  width: 100%;
}
.testmonialsTest {
  font-weight: bold;
  font-size: 24px;
  margin: 0px 10px;
 
}
.testmonials-tag{
  background: #444B66;
  border-radius: 7px;
  font-size: 12px;
  color:#ffffff;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 22px;
  
    }
    .develop-tags {
      background-color: #c5aa46;
      color: #FFFFFF;
      
    }
    .broker-tags{
      background-color: #0b99a7 !important;
      color: #FFFFFF;
     
    }
.testmonialsSpan {
  margin: 0px 10px;
}
.react-switch-handle {
  box-shadow: none !important;
}
.propertyButton {
  position: absolute;
  right: 20px;
  top: 50px;
}
.divWrapFirst,.secondDivWrap{
  width: 50%;
  word-wrap: break-word;
}
.testWrappers{
  .slick-dots{
    margin: 0px 50px !important;
    overflow: hidden !important;
    height: 40px !important;
  }
}