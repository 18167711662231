.markersList {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  /* margin: auto; */
  color: black;
  /* margin-top: 30px; */
  overflow-x: auto;
  height: 80px;
  padding: 20px;
}

.markerItem {
  margin:7px 10px;
  border-bottom: 1px solid transparent;
  font-size: 15px;
  width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.markerSelectedItem {
  margin:7px 10px;
  border-bottom: 1px solid transparent;
  font-size: 15px;
  width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #015169;
  font-weight: 500;
}
.markerItem:hover {
  border-bottom: 1px solid #015169;
}
.iconImg{
  width: 25px;
  height: 25px;
}
.placeColumn{
text-align: center;
}
.iconContainer{
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
}
.iconSelectedContainer{
  padding: 10px;
  border-radius: 50%;
  border:1px solid #015169;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
}
.iconContainer:hover{
  padding: 15px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);;
}