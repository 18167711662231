.boxheader {
  color: #000000;
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 22px;
}

.textheader {
  color: rgba(21, 25, 32, 0.5);
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.radioform {
  display: flex;
  justify-content: space-between;
}

.subform {
  width: 100%;
  /* margin-top: 30px; */
}

.formradiobuttons {
  display: flex;
  /* justify-content: space-between; */
}

.formradiobuttons > div {
  display: flex;
  align-items: center;
}

.form-check {
  margin-left: 30px;
}

.form-check-input {
  margin-right: 10px !important;
}

.demandradiobutton {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}

.existingsubmitlabel {
  color: #000000;
  font-weight: 700;
}

.boxheaderdemand {
  display: flex;
  justify-content: space-between;
}
.colorInfo {
  color: #015169;
  cursor: pointer;
}
.tooltipCustom {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted rgb(114, 108, 108);
}

.tooltipCustom .tooltiptextCustom {
  visibility: hidden;
  width: 120px;
  background-color: rgb(114, 108, 108);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -40px;
  right: 0px;
}

.tooltipCustom:hover .tooltiptextCustom {
  visibility: visible;
}
