.demand-detail-lable {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    border-radius: 6px;
    padding: 5px;
    padding-left: 10px;

}

.demand-detail-lable-left {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border-radius: 6px;
    padding: 5px;
    padding-left: 0px;

}

.bg-light-card {
    background: rgba(127, 162, 184, 0.16) !important;
    border-radius: 6px;
}

.format-cards {
    width: 45%;
    display: flex;
    border-radius: 6px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.format-margin-right {
    margin-right: 15px;
}

.format-margin-left {
    margin-left: 15px;
}