.manage-roles {
  .accordion-button:not(.collapsed) {
    // color: var(--bs-accordion-active-color);
    background-color: white !important;
    border: none !important;
    box-shadow: none !important;
  }
  .accordion-button:focus {
    box-shadow: none !important;
  }
  .accordion_custom {
    margin-left: 12px !important;
  }
  .form-check-input {
    margin-right: 10px !important;
  }
  .parent-header {
    padding: 5px 0px;
  }
  .table-btn{
    svg{
      height: 22px;
    }
  }
  .child-elements {
    display: flex;
    flex-direction: column;
    padding: 5px 50px;
    .form-check {
      padding: 5px 0px;
    }
  }
}
.roles-error {
  .invalid-feedback {
    position: absolute;
    left: 27% !important;
    font-size: 11px;
    top: 55px;
    font-size: 11px;
  }
}
.button-none {
  background: white !important;
  border: none !important;
}
@media (max-width: 700px) {
  .roles-error {
    .invalid-feedback {
      position: absolute;
      left: 3% !important;
      font-size: 11px;
      top: 100px;
      font-size: 11px;
    }
  }
}
