.Property-View-lable {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  border-radius: 6px;
  padding: 5px;
  padding-left: 10px;
}
.Property-View-lable-left {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  border-radius: 6px;
  padding: 5px;
  padding-left: 10px;
}
.pdf-viewer {
  /* display: flex !important; */
  justify-content: flex-end !important;
  /* color: #000!important; */
}
.pdfLink{
  color: #000 !important;
}
.Property-amenties-lable {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  border-radius: 6px;
  height: 140px;
  width: 100%;
}
.Property-amenties {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #000;
  padding: 0px 10px;
  text-align: center;
}
.amenties-row {
 display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  justify-content: space-between;
}
.propertynearby-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  justify-content: space-between;
  flex-direction: row !important;
}
.Property-nearby{
  padding: 0px 10px;
}
.Property-nearby , 
.developer-name{
  color: #000 !important;
}
.name-of-property {
  color: #000 !important;
  font-size: 22px;
}



@media only screen and (max-width: 900px) {
  .amenties-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: space-between;
    flex-direction: row !important;
  }
  .propertynearby-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    justify-content: space-between;
    text-align: center;
    flex-direction: row !important;
  }
}

@media only screen and (max-width: 600px) {
  .amenties-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: space-between;
    flex-direction: row !important;
  }
  .propertynearby-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    justify-content: space-between;
    text-align: center;
    flex-direction: row !important;
  }
}
@media only screen and (max-width: 400px) {
  .amenties-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    justify-content: space-between;
    flex-direction: row !important;
  }
  .propertynearby-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    justify-content: space-between;
    text-align: center;
    flex-direction: row !important;
  }
}
.widthAdj{
  overflow-wrap: break-word;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding: 0px !important;
}
.widthWrapper{
  padding-left: 0px !important;
}
.pdfIcon{
  width: 30px;
}
.pdfWrapper{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.aminitiesSize{
  max-height: 60px;
}