$select-bg: rgba(123, 151, 206, 0.68);
$input-palceHolder-color: #818384;
.upload-lable {
  display: block;
  font-size: 1rem;
  color: #000;
}

.upload-container {
  padding: 10px;
  min-height: 50px;
  background-color: var(--input-bg);
  border: 2px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  display: block;

  position: relative;
  /* overflow-x: auto; */
}

.upload-container-wrapper {
  width: 90%;
  display: block;

  /* overflow-x: auto; */
}

.upload-list {
  background-color: $select-bg;
  border-radius: 26px;
  color: #fff;
  padding: 8px;
  white-space: nowrap;
  text-align: center;
  max-width: 90%;
  height: 35px;

  /* width: fit-content; */
}

.upload-container .upload-input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: $input-palceHolder-color;

  /* width: fit-content; */
}

.upload-container .upload-icon {
  position: absolute;
  right: 10px;
  top: 20%;
  background-color: white;
}
.upload-container .upload-list {
  background-color: $select-bg;
  border-radius: 26px;
  color: #fff;
  padding: 8px 8px;
  align-items: center;
  /* width: fit-content; */
  max-width: 75%;
}
.upload-container .upload-image-text {
  width: 80%;
  /* flex-wrap: nowrap; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-container .upload-icon {
  position: absolute;
  right: 10px;
  top: 30%;
}
.uploadUIWidth {
  max-width: 90%;
}
.NameOfFile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
.closeButtonUpload {
  margin-top: 5px;
}
.upload-list {
  display: flex;
}
.outerRed {
  border: 1px solid #dc3545;
}
