.filter_wrapper{
    width: 100%;
    height:50px;
    position:relative;
    margin-bottom: 15px;
  }
  .filter_container{
    width: 50%;
    
  }
  @media (max-width: 900px){
    .filter_container{
      width: 200px;
    }
  }