.filter-container {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.filter-container .form-select {
    width: 20%;
}



//demandcard

.demand-card-container {
    .title-text {
        margin: 0px 1rem;
    }
}

// detail card
@media (max-width: 900px) {
    .detail-card {
        .mobileWrapper {
            word-break: break-all;
            width: 50% !important;
        }

    }
}

@media (max-width: 1000px) {
    .demand-card-container {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 700px) {
    .demand-card-container {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media (max-width: 400px) {
    .demand-card-container {
        grid-template-columns: repeat(1, 1fr) !important;

        .demand-title {
            flex-direction: column !important;
        }

        .title-text {
            margin: 1rem 0px 0px 0px !important;
        }
    }
}

@media (max-width: 550px) {
    .detail-card {
        .row {
            display: block !important;
        }

        .detail-card-text {
            width: 100% !important;
        }

    }
}

.detail-card {
    .detail-card-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 40%
    }
}

//brandpage styles
.brandPageForm-container {
    background: #ffffff;
    padding: 20px;
    .invalid-feedback {
    position: unset;
    display: block;
    bottom: -22px;
    left: 0;
    font-size: 11px;
    color: #dc3545;
    }
}

.brandPage-container {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
    background: #ffffff;

    .card-container {
        gap: 20px;
        margin-bottom: 35px
    }

    .brand-image {
        height: 220px;
    }

    .brand-profile {
        width: 50%;
    }

    .brandPage-content {
        padding: 24px
    }

    // .invalid-feedback {
    //     display: block;
    //     width: 100%;
    //     left: 0;
    //     font-size: .875em;
        
    // }

    .profile-container {
        position: relative;
        margin-bottom: 35px
    }

    .brand-profile {
        width: 130px;
        height: 130px;
        object-fit: cover;
        padding: 6px;
        position: absolute;
        top: -58px;
        border-radius: 50%;
        background-color: #ffffff;
    }

    .profile-content {
        padding: 0px 0px 0px 150px;
    }

    .subscription-card {
        .row {
            row-gap: 10px;
            column-gap: 20px;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            border-radius: 20px;
            padding: 15px;

            .subscription-detail-columns {
                width: 48%;
                display: flex;
                border-radius: 6px;
                padding: 10px 20px;
                background: rgba(127, 162, 184, 0.16);
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: 0;
                }

                .subscription-key {
                    font-size: 20px;
                    color: #000000;
                    width: 70%;
                }

                .subscription-value {
                    font-size: 20px;
                    color: #000000;
                    width: 30%;
                    text-align: left;
                }
            }
        }
    }
}

.detail-card {
    width: 100%;
    padding: 20px 24px 7px 24px;
    border-radius: 10px;
    background: #EBF0F4;
    margin-bottom: 35px
}

@media (max-width: 900px) {
    .detail-card {
        .mobileWrapper {
            word-break: break-all;
            width: 50% !important;
        }
    }
}

@media screen and (max-width: 600px) {
    .subscription-card {
        .row {
            .subscription-detail-columns {
                width: 100% !important;
            }
        }
    }

    .filter-container .form-select {
        width: 50%;
    }
}

//demand styles
.demand-card-container {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 40px;

    .demand-card {
        box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
        border-radius: 8px;
    }

    .demand-profile {
        width: 90px;
        height: 83px;
    }

    .responses {
        color: #3FA674;
        display: block;
        text-align: center;
        line-height: 21px;

        .responses-count {
            font-size: 32px;
            font-weight: 700;
        }

        .response-text {
            font-size: 12px;
            font-weight: 500;
        }
    }
}

.city-card-container {
    grid-template-columns: repeat(5, 1fr);
    display: grid;
    gap: 40px;
    margin-bottom: 35px;

    .city-card {
        box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
        border-radius: 8px;
        padding: 40px;
    }

    .city_image {
        width: 130px;
    }
}


// brand page style ends
.report-card-container {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 40px;
    margin-bottom: 35px;

    .report-card {
        box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
        background: #EBF0F4;
        border-radius: 10px;
        padding: 10px 10px 10px 25px;
    }
}

//-------------------------------------------------------------------------------------------


//developer page styles


@media screen and (max-width: 700px) {
    .filter-container {
        display: flex;
        // justify-content: center;
    }

    .filter-container .form-select {
        width: 50%;
    }
}

.btn-container {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

@media (max-width: 900px) {
    .mobilePosition {
        margin-bottom: 10px;
    }

    .btn-container {
        margin-top: 10px;
    }
}

//warning popup
.warning-header-class {
    padding: 20px 1rem !important;
}
.view-all-btn{
    color:#000000;
    font-weight: bold;
    font-size: 11px;
}