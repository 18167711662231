.privacy_fullpage {
  // box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  .privacy_text_header {
    color: var(--primary-active-color);
    font-size: 24px;
    line-height: 28px;
    font-family: var(--primary-font);
    font-weight: 500;
  }
  .privacy_edit_button {
    font-family: var(--primary-font);
    font-size: 16px;
    background-color: #034168 !important;
    color: #ffffff !important;
    border-radius: 4px !important;
    padding: 5px 34px !important;
  }
  .content_fullcards {
    background-color: #ffffff;
    padding: 30px 30px;
    margin: 0px 25px;
    border-radius: 8px;
    .privacy_text {
      /* font-family: var(--primary-font); */
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.05em;
      color: var(--privacy-text);
      overflow-wrap: break-word;
    }
  }
  .editCKeditors {
    padding: 20px;
    .privacy-button-footer {
      display: flex;
      justify-content: flex-end;
    }
    .privacy_update_button {
      font-family: var(--primary-font);
      font-size: 16px;
      background-color: #034168 !important;
      color: #ffffff !important;
      border-radius: 4px !important;
      padding: 8px 34px !important;
      margin-top: 30px !important;
      margin-bottom: 40px !important;
    }
  }
}
