.autocomplete-container{
    position: relative;
    z-index: 1;
    width: 100%;
   
    .autocomplete-dropdown-container{

    position: absolute;
    top:100%;
    bottom: 0;
    background-color: #fff;
    width:100%;
    height: 0;
    z-index: 999;
    
  }
}
