input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.search::placeholder {
  color: #181059;
}

.search,.chat-search {
    background: rgba(127, 162, 184, 0.16);
    border: none;
    border-radius: 6px;
    padding: 9px;
    padding-left: 45px;
    outline: none;
    width: 100%;
}
.chat-search{
  padding-left: 39px;
  border-radius: 20px;
  border: 1px solid #313131;
  height: 100%;
}
.search-icon {
  color: #181059;
  position: absolute !important;
  top: 13px;
  left: 15px;
}

.search-bar {
  position: relative !important;
}
@media (max-width: 768px){
  .search-bar{
    margin-right: 10px;
  }
}
.search-input{
  margin-right: 10px;
}