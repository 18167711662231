.report-card-container {
  margin: 30px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;

  .report-card-items{
      // background: #EBF0F4;
      // border-radius: 10px;
      // padding: 10px 10px 10px 10px;
      // text-align: center;
      // padding: 20px 15px;

      background: #ffff;
      border-radius: 10px;
      text-align: center;
      padding: 20px 15px;
      justify-content: center;
      align-items: center;

      .report-card-heading{
            // font-size: 20px;
            // color: #000000;
            // font-weight: 500;
            // letter-spacing: 0.005em;
            // width: 100%;

            font-size: 17px;
            font-weight: 700;
            margin: 15px 0px;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
           
           
      }
      .report-card-value{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 49px;
        font-size: 24px;
        font-weight: 500;
        color: #015169;
        border-radius: 3.62694px;
        background-color: #ffffff;
        border: 0.725389px solid #ebf0f4;
        position: relative;
      }
      .btn-view {
        color: #015169;
        background-color: #ffffff;
        border: 0.73px solid #015169;
        border-radius: 4px;
      }
  }
}
