.rootDivision{
    background-color: #ffffff;
    /* padding: 15px 20px; */
    margin: 0px 25px;
    border-radius: 8px;
}
.profiletext_Container{
    background-color:#7FA2B8;
    height:5vh;
    display: flex;
    align-items: center;
}
.profiletext{
    color:#fff;
    font-size:20px;
   
    margin-left: 25px !important;
}
.containerWrapper_mainContent{
    /* background-color: #ffffff; */
    padding: 15px 20px;
    margin: 0px 25px;
    border-radius: 8px;
}
.label_1{
    color: #000000;
    font-weight: 700;
    font-size: 16px;
}
.button_division{
    display:flex;
    justify-content: flex-end;
    padding: 15px;
}
.lable{
    margin-bottom: 15px;
    color: #000000;
    font-weight: 700;
    font-size: 14px;
}
/* .div_{
    height:5vh;
    display: flex; 
} */
.PhoneInputInput{
    height: 50px;
    border-radius: 5px;
    border: 1px solid #818384;
}
.PhoneInput{
    margin-top:15px
}

@media (max-width: 768px) {
    .socialLinks{
        display: block !important;
    }
    .containerWrapper_mainContent {
        padding: 0px;
    }
    .settingCustom{
        display: block !important;
    }
    .w-50{
        width: 100% !important;
    }
}
.settingCustom{
    background-color:#fff;
    border-radius:5px;
    padding: 20px;
}
.settingCustom .switch-custom-toogle .form-check-input{
   width:2.7em !important;
   height:1.4em !important;

}
.tooltipCustom {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted rgb(114, 108, 108);
    cursor: pointer;
    margin-left: 5px;
  }
  
  .tooltipCustom .tooltiptextCustom {
    visibility: hidden;
    width: 200px;
    background-color: rgb(114, 108, 108);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 2px;
    font-size: 10px;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 10px;
    right: -220px;
  }
  
  .tooltipCustom:hover .tooltiptextCustom {
    visibility: visible;
  }