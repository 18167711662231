.header {
  display: flex;
  justify-content: space-between;
  top: 0;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 75px;

  .header-left {
    background: #fff;
    padding: 26px 30px;
    width: 255px;
    img {
      width: 100%;
    }
  }
  .menu-icon {
    // width: 20%;
    display: none;
  }
  .header-right {
    display: flex;
    gap: 30px;
    justify-content: flex-end;
    padding: 21px 30px;
    width: calc(100% - 255px);
    box-shadow: 0px 2px 9px rgb(0 0 0 / 14%);
    svg {
      // width: 26px;
      // height: 27px;
      color: #ffffff;
    }
  }
  .msg-icon {
    position: "relative";

    img,
    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
  .badge {
    color: "#fff";
    position: absolute;
    z-index: 10;
    top: 15px;
    background-color: red;
    border-radius: 50%;
  }
}
@media (max-width: 900px) {
  .header-left {
    display: flex;
    padding: 22px 15px !important;
    img {
      width: 80% !important;
    }
  }
  .menu-icon {
    width: 20%;
    display: block !important;
    cursor: pointer;
  }

  // .logo{
  //   width: 80%!important;
  // }
}
