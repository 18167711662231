.post-new-property {
  position: relative;
  background: #FFFFFFFF;
  .hidebody {
    position: absolute;
    height: calc(100% - 90px);
    width: 100%;
    background: transparent;
    top: 0;
    left: 0;
    z-index: 10;
  }
  form {
    max-width: 100%;
  }
}

.customGoogleMapInvalid{
  width: 100%;
  margin-top: -3rem;
  font-size: .875em;
  color: #dc3545 !important;
}
