/************** Content Brands *************/
.brand_text,
.content_demand_text {
    
}

.brand_button {
    color: #034168 !important;
    background-color: var(--white) !important;
    /* padding: 0px 20px !important; */
    font-size: 16px !important;
    font-weight: 500 !important;
}

.brand_image {
    width: 85px !important;
    height: 85px !important;
    /* padding-bottom: 10px; */
}

.content_brand_card {
    padding: 18px;
}



.brand-text {
    color: var(--black);
  
    font-size: 24px;
}

.business_image {
    border-radius: 12px 12px 0px 0px;
    padding: 0px;
}

/******* Content Demands Feature *********/
/* 
.content_demand_text {
    color: var(--black);
    font-size: 24px;
} */

.new_demand_title {
    color: var(--dark-shadow-text);
    font-weight: 700;
}

.view_demand_button_sub {
    background-color: var(--progress-active-bg) !important;
    color: var(--white-text) !important;
    font-weight: 600 !important;
    padding: 9px 24px !important;
}

.new_demands_box {
    box-shadow: 0px 2px 16px rgba(60, 62, 74, 0.3);
    border-radius: 8px;
    padding: 20px;
    /* width: 100% !important; */
}

.new_demand_properties {
    text-align: start;
    padding: 10px !important;
    margin: 0 !important;
}

.content_demand_image {
    width: 18% !important;
    margin: 0;
}


.view_demand_button {
    display: flex;
    justify-content: flex-end;
}

/************ Content Demands New ************/


.content_demand_text {
    color: var(--black);
    font-size: 24px;
}

.new_demand_title_1 {
    color: var(--dark-shadow-text);
    font-weight: 700;
    padding-left: 10px;
}

.view_demand_button_sub {
    background-color: var(--progress-active-bg) !important;
    color: var(--white) !important;
    font-weight: 600 !important;
    padding: 9px 24px !important;
    text-align: end !important;
}

.new_demands_box {
    box-shadow: 0px 2px 16px rgba(60, 62, 74, 0.3);
    border-radius: 8px;
    padding: 10px;
    height: fit-content;
    position: relative;
    /* width: 92%; */
}

.new_demand_properties {
    text-align: start;
    padding: 5px 10px !important;
    margin: 0 !important;
}

.content_demand_image_new {
    /* width: 70px !important;
    margin: 0; */
    width: 90px !important;
    margin: 0;
    height: 75px !important;
    border-radius: 10px;
}

.new_demand_address_1 {
    text-align: start;
    padding-left: 10px;
    /* margin-top: 25px; */
}

.view_demand_button {
    text-align: end !important;
}

.content_demand_image_feature {
    width: 75px !important;
    height: 100% !important;
    margin: 0;
    word-break: break-all;
}




/******* Edits *********** /

/* .button_Edit {
    background-color: #034168;
    color: #ffffff;
    padding: 4px 30px 4px 30px;
    display: flex;
    justify-content: space-between;

}  */

.content_text {
    color: var(--primary) !important;
    font-size: 24px;
}

.edit_button {
    background-color: #034168 !important;
    color: var(--white) !important;
    border-radius: 4px !important;
    padding: 0px 30px !important;
}

.new_demand_title_2 {
    color: var(--dark-shadow-text);
    font-weight: 700;
    /* padding-left: 10px; */
    overflow: hidden;
    max-height: 50px;
    align-self: center;
}

.new_demand_address_2 {
    text-align: start;
    padding-left: 10px;
    margin-top: 10px;
}

/***********  Footer ******************/

.content_footer {
    font-family: var(--primary-font);
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    /* width: 100%; */
    padding: 60px;
    text-align: left;
}

.content_color {
    background-color: #3F4255;
}

.footer-header {
    font-size: 24px;
    font-weight: 500;
}

.content-links {
    display: flex !important;
    justify-content: space-between;
    padding: 12px 100px 40px 80px;
}

.content-icon {
    font-size: 18px;
    cursor: pointer;
}

.footer-quick {
    text-align: center;
}

.footer_text_end {
    font-weight: 600;
    gap: 32px;
    cursor: pointer;
}

.content-address {
    width: 70%;
}

.content-links-about {
    padding-right: 50px;
    cursor: pointer;
    color: #D4D7E6;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}

.content-links-contact {
    padding-right: 35px;
    cursor: pointer;
    color: #D4D7E6;
    line-height: 20px;
    font-weight: 700;
}

.content-links-faq {
    padding-right: 80px;
    cursor: pointer;
    color: #D4D7E6;
    line-height: 20px;
    font-weight: 700;
}

.content-links-blogs {
    cursor: pointer;
    color: #D4D7E6;
    line-height: 20px;
    font-weight: 700;
}

hr {
    width: 65%;
    color: #ffffff;
}

.footer-emailID {
    cursor: pointer;
}


/**************** Media Queries *****************/

@media only screen and (max-width: 600px) {
    .contentview-responsive {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
}
@media (max-width: 768px) {
    .content_footer{
        padding: 10px !important;
    }
    .content-links{
        padding: 10px !important;
        display: block !important;
    }
    .content-icon{
        justify-content: center !important;
        padding: 10px !important;
    }
    .new_demands_box{
        width: 100% !important;
    }
}