.ck.ck-editor{
    display: flex;
    flex-direction: column-reverse;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border: none;
    border-radius: 5px;
}
.ck.ck-toolbar__items{
    padding: 10px;
    gap: 20px;
}
.ck.ck-toolbar {
    // background: #EFEFEF;
    border-radius: 5px;
    border: none;
}
.ck p{
    padding: 25px;
}
.ck.ck-toolbar>.ck-toolbar__items{
    justify-content: space-around;
}
.ck.ck-sticky-panel__placeholder {
    display : none !important;
  }
  .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    position: unset;
    width: 100% !important;
  }
 
  
  .ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit!important;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px!important;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal!important;
}
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px!important;
}