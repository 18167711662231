.gallery-conatiner{

    background-color: black;
}

.galleryBox-container{
    background-color: black;
  
    
  
}
.galleryBox-container .gallery-image{
   
   max-width:500px;
   margin:0 auto;
    
}
.galleryBox-container .gallery-image img{
  
  width:100%;
  object-fit: cover;
    
}
.galleryBox-footer .gallery-list-image{

    width:20%;
    

}
.galleryBox-footer .gallery-list-image img{

    width:100%;
    object-fit: cover;

}

.galleryBox-container .slick-arrow.slick-prev,
.galleryBox-container .slick-arrow.slick-next {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.IoIosArrowForwardIcon {
  background-color: rgb(10, 11, 11) !important;
  z-index: 2 !important;
  position: relative !important;
}
.galleryBox-container .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.galleryBox-container .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom: 45px !important;
}
.custom-indicator li {
  width: 100px !important;
  height: 50px;
}

  .gallery-lists{

height: 150px;
display: block !important;


  }

  .gallery-lists img{
width:100%;
display: block;
height: 100%;
object-fit: cover;


  }
  .mainImage{
    max-width: 700px;
    margin: 0 auto;
    border: 2px solid #FFFF;
  }
  .galleryBox-container .slick-initialized .slick-slide{
    text-align: center;
    
  }
 
  /* .galleryBox-container .slick-current{
    border: 2px solid greenyellow;
  } */
   .galleryBox-container .galleryBox-subimage-container .slick-current{
    border: 2px solid #FFFF;
  
  }
   /* .galleryBox-container .galleryBox-subimage-container .slick-active{
    outline: 10px solid rgb(14, 14, 14);
  
  } */
   .galleryBox-container .galleryBox-subimage-container  .slick-slide{
    outline: 10px solid rgb(14, 14, 14);
  }
  
  /* .galleryBox-container .galleryBox-image-container{
   max-width: 900px;
   margin: 0 auto;
  }  */
.text-white{
    color:#FFFF;
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 64px;
}
