.chat {
  // font-family: "Gilroy";
  overflow: hidden !important;
  margin: -2rem !important;
  .user-list {
    background: #deebf2;
    .static-data {
      padding: 1rem 1rem 1rem 2rem;
      height: 110px;
    }
    .user-cards {
      height: calc(100vh - 185px);
      // min-height: 470px;
      overflow-y: auto;
      .chat-card,
      .chat-card-active {
        height: 55px;
        max-width: 100%;
        padding: 5px 1rem 5px 2rem;
        align-items: center;
        justify-content: space-between;
        .text-person, .text-last-msg{
          line-height: 17px;
        }

      }
      .chat-card-active {
        background-origin: border-box;
        background: #ffff;
      }
      .chat-count {
        width: 20px;
        height: 20px;
        background: #034168;
        border-radius: 50%;
      }
    }
  }

  .chat-channel {
   
    .chat-head {
      background: #deebf2;
      padding: 1rem 2rem;
      height: 80px;
    }
    .name-logo,
    .chat-logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: linear-gradient(0deg, #034168, #034168), #c4c4c4;
      color: white;
      margin-right: 10px;
    }
    .chat-logo {
      width: 40px;
      height: 40px;
    }
    .datail-chat {
      width: 100%;
      height: calc(100vh - 225px);
      background-color: #e5e5e5;
      overflow: hidden !important;
      overflow-y: scroll !important;
      .newday-alert {
        width: 100%;
        height: 50px;
      }
      .message-box-send {
        display: flex;
        flex-direction: row-reverse;
        max-width: 100%;
        height: fit-content;
        word-break: break-all;
        overflow-wrap: anywhere;
        padding: 10px;
      }
      .message-time {
        display: flex;
        flex-direction: row-reverse;
      }
      .message-box-receive {
        display: flex;
        width: 100%;
        height: fit-content;
        word-break: break-all;
        padding: 10px;
        overflow-wrap: anywhere;
      }
      .send-message-card {
        background: #034168;
        border-radius: 16px 16px 0px 16px;
        padding: 10px;
        color: #fcfdfe;
        display: flex;
        flex-direction: row-reverse;
      }
      .receive-message-card {
        background: #f5f5f5;
        padding: 10px;
        border-radius: 16px 16px 16px 0px;
      }
    }
    .enter-message {
      background-color: #ffff;

      height: 68px;
      width: 100%;
      position: relative;
    }
    // .enter-message-img1 {
    //   content: "";
    //   position: absolute;
    //   top: 35%;
    //   right: 8%;
    //   height: 20px;
    //   width: 20px;
    // }
    .enter-message-img2 {
      content: "";
      position: absolute;
      top: 40%;
      right: 3%;
      height: 20px;
      width: 20px;
    }
    .pdfContainer{
      background-color: #034168;
    }
    .pdfIcon{
      width:  20px;
      height: 20px;
      margin-right: 10px!important;
    }
    .noBorder{
      border: none;
    }

    .message_input {
      position: relative;
      width: 90%;
      height: 40px;
      background-color: #e5e5e5;
      border-radius: 5px;
      outline: none;
      border: 0px;
      font-size: 16px;
      color: black;
      padding: 0 10% 0 20px ;
      word-wrap: break-word;
      word-break: break-all;
    }
    .text_areas{
      margin: 3px;
      background: #e5e5e5;
      display: flex;
      align-items: center;
    }
    .chat-text{
      background:transparent;
      width: 90%;
      border-color: none !important;
      border: none!important;
      border-radius: 5px;
      outline: none;
      border: 0px;
      font-size: 16px;
      color: black;
      padding: 0 10% 0 20px ;
      word-wrap: break-word;
      word-break: break-all;
    }
    .attach-file{
      background:transparent;
      border: none;
    }
  }
}
