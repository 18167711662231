.seller-report-table {
  .table-link {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    color: #034168 !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 15px !important;
    border: none !important;
    // text-decoration: underline;
  }
}

.brand-with-logo{
    display: flex;
    gap:10px;
    align-items: center;
    justify-content: space-between;
      .logo{
          width:30px;
          height: 30px;
          img{
              width: 100%;
              height: 100%;
              object-fit: cover;
          }
      }
  }
