.table-link-btn {

  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
  color: #034168 !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  border: none !important;

  &:hover {
    border: none !important;
  }
}

.table-btn-text {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
  color: #034168 !important;
  // font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  cursor: auto !important;

  &:hover {
    border: none !important;
  }
}

.table-btn {
  border: none !important;

  &:hover {
    border: none !important;
  }
}


.customer-list {
  .customer-list__name {
    font-weight: 700;
    font-size: 16px;
    color: #3E4958;
  }

  .customer-list__role {
    font-weight: 400;
    font-size: 14px;
    color: #3E4958;
  }

  .customer__change {
    max-height: 50vh;
    overflow-y: auto;
  }

  .customer__change>div:first-child {
    border-top: 0.5px solid #D9D9D9;
    border-bottom: 0.5px solid #D9D9D9;
  }

  .customer__change_rm {
    border-bottom: 0.5px solid #D9D9D9;
  }

  .change_rm__name {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }

  .customer__change_rm .change_rm__action {
    font-weight: 500;
    font-size: 14px;
    color: #034168;
  }

  .modal-body>div:first-child {
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
  }
}

.widWrapper {
  width: 20%;
}

.manageWidthSearch {
  width: 50%;
}

.flexEnd_div {
  justify-content: flex-end !important;
}

.error-message_span {
  color: #dc3545;
  font-size: 12px;
}

// .admin-profile-container {
//   text-align: center;
//   width: 100%
// }

// .admin-profile-container {
//   position: relative;
//   width: 200px;
//   height: 200px;
//   overflow: hidden;
//   border-radius: 50%;
// }

// .admin-profile {
//   width: 100%;
//   height: auto;
// }

.image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-container{
  width: 100%;
  // background-color: #750404;
  text-align: center;
}

.admin-profile-container {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  margin: 5px 0;
  border-radius: 50%;
}

.admin-profile {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.primary-color {
  color:#034168;
}

.change-text {
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
}
// .admin-profile {
//   width: 25%;
//   object-fit: fill;
//   border-radius: 50%;
// }