.profileWrapper {
  position: relative;
  height: 100px;
  .imageWrapper {
    position: absolute;
    right: 5%;
    .imagePic {
      
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      text-align: center;
      margin: 0 auto;
    }
    .profileDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        label {
          cursor: pointer;
        }
      }
    }
  }
  .currentPlanCard .card{
    display: -webkit-inline-box !important;
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;    
}

.currentPlanCard .card-body{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
}
.w-region{
  width: 475px;
}
.currentPlanCard .card{
  display: -webkit-inline-box !important;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;    
}

.currentPlanCard .card-body{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 550px) {
  .detailsForm{
    .row{
      flex-direction: column;
    }
  }
}
.brandPageForm-container{
.form-group{
  .invalid-feedback {
    position: absolute;
    bottom: -19px;
    left: unset;
    font-size: 11px;
  }
}
}
.form-group {
  margin-bottom: 20px;
  position: relative;

  input.form-control {
    height: 40px;
    color: #555555;
    font-size: 16px;
    font-weight: 500;
    &.lg {
      height: 50px;
    }
  }
 
}