.form-group {
  margin-bottom: 20px;
  position: relative;

  input.form-control {
    height: 40px;
    color: #555555;
    font-size: 16px;
    font-weight: 500;
    &.lg {
      height: 50px;
    }
  }
  .invalid-feedback {
    position: absolute;
    bottom: -19px;
    // left: 13px;
    left: unset;
    font-size: 11px;
  }
}
.w-40{
  width: 40%;
}
.phonenumber-input{
    
  .form-select.is-invalid:not([multiple]):not([size]){
    padding-right: 2.125rem !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
  }
  .invalid-feedback {
    left: 0 !important;
  }
  .was-validated .form-select:valid:not([multiple]):not([size]){
    padding-right: 2.125rem !important;
  }
}
.phonenumber-select{
  height: 40px !important;
  padding-right: 2.125rem !important;
}
.phonenumber-control{
margin-left: -5px !important;
border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}
.sufixWrapper {
  position: absolute;
  right: 17px;
  bottom: 10px;
}
.prefixWrapper{
  position: absolute;
  bottom: 8px;
  left: 10px;
}
.maxWidthStyle{
  max-width:360px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
