.property-card-container {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 20px;
  color: #3e4958;
  .responses {
    color: #3fa674;
    display: block;
    // text-align: center;
    line-height: 21px;

    .responses-count {
      font-size: 32px;
      font-weight: 700;
      margin-right: 20px;
    }

    .response-text {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .property-end-style {
    display: block;
    text-align: end;
    display: inline-block;
    margin-left: auto;
  }
}

.property-profile {
  width: 200px;
}

.property-detail {
  width: 48%;
}
.ideal {
  word-break: break-all;
}

.property-card {
  box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;

  .ribbon-wrapper {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .ribbon-wrapper::before {
    content: "Featured";
    position: absolute;
    width: 146%;
    height: 28px;
    background: #ed3c3f;
    transform: rotate(-45deg) translateY(-14px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.1em;
    box-shadow: 0.5px 5px rgb(0 0 0 / 6%);
  }

  .ribbon-wrapper::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background: #c1474a;
    z-index: -1;
    box-shadow: 110px -110px #c1474a;
  }
}
.propertyCard-container {
  color: #3e4958;
 
  .row {
    height: 100%;

    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    
  }

  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;

  .content {
    margin-top: 0;

    .image {
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 6px;
      overflow: hidden;
    }

    .inner-content {
      h5 {
        margin-bottom: 2px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;

        p {
          width: 50%;
          margin-bottom: 7px;

          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .tag-style {
    margin-bottom: 15px;
    background: #c5aa46;
    border-radius: 18px;
    padding: 0px 22px;
    color: #ffffff;
  }
  .brokerColor {
    background: #a0c8eeb2;
  }
  .posted-by-name {
    color: #034168;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: flex-end;
    .btn-list {
      column-gap: 10px;
    }

    .btn-content {
      display: block;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      justify-content: flex-end;
      row-gap: 18px;
      height: 100%;
    }

    p {
      margin-bottom: 0;
      color: #3e4958;
    }

    div {
      display: flex;
    }

    h4 {
      color: var(--dark-shadow-text);
      font-weight: 700;
    }
  }

  .completed-style {
    margin-bottom: 15px;
    background: #3fa674;
    border-radius: 18px;
    padding: 0px 22px;
    color: #ffffff;
  }
  .pending-style {
    margin-bottom: 15px;
    background: #a6833f;
    border-radius: 18px;
    padding: 0px 22px;
    color: #ffffff;
  }

  .colors {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;

    h4 {
      width: 100%;
      margin-bottom: 0;
      line-height: 20px;
      font-weight: 700;

      &.green {
        color: #3fa674;
      }

      &.red {
        color: #a6833f;
      }

      span {
        font-size: 12px;
        display: block;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .property-card-container {
    .parent-col {
      width: 50% !important;
    }
    .content {
      .inner-content {
        > div {
          display: block !important;
        }
      }
    }
    .sub-col-9 {
      width: 70% !important;
    }
    .sub-col-3 {
      width: 30% !important;
    }
    .btn-list {
      width: 100% !important;
      flex-direction: column !important;
      row-gap: 10px !important;
    }
  }
}

.property-card-container {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 20px;
  color: #3e4958;

  .responses {
    color: #3fa674;
    display: block;
    // text-align: center;
    line-height: 21px;

    .responses-count {
      font-size: 32px;
      font-weight: 700;
      margin-right: 20px;
    }

    .response-text {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .property-end-style {
    display: block;
    text-align: end;
    display: inline-block;
    margin-left: auto;
  }
  .property-profile {
    width: 28%;
    aspect-ratio: 16/9;
  }
  .property-card-left-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
    .row {
      
      row-gap: 20px;
      width: 100%;
    }
  }

  .property-detail {
    width: 48%;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ideal {
    word-break: break-all;
  }

  .property-card {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
    border-radius: 8px;
    margin-bottom: 15px;
    // height: 248px;
  }
  .property-end-style {
    button {
      padding: 8px !important;
    }
  }
}

.paddStyle {
  padding: 0px 10px;
}

.broker-lable_custom {
  color: #ffffff;
  text-align: center;
  padding: 3px 20px;
  background: #0b99a7;
  border-radius: 18px;
  width: fit-content;
  margin: 0px !important;
}
.devloper-lable {
  color: #ffffff;
  text-align: center;
  padding: 3px 20px;
  background: #c5aa46;
  border-radius: 18px;
}
