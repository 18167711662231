.dropZone-ImageSize{
    position: relative;
  }
  .images-dropzone{
    width: 150px;
    height: 150px;
    margin: 2px 5px;
  }
  .remove-image{
    color: #DD2025;
      width: fit-content;
      background-color: white;
      border-radius: 10px;
      position:absolute;
      top: 5px;
      right: 10px;
      padding: 1px 4px;
      cursor: pointer;
  }
  .image-dropzoneWrapper{
    display: flex;
      flex-wrap: wrap;
      gap:15px;
  }
  .drop-container{

background: rgba(127, 162, 184, 0.02);
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
padding: 10px;
padding-bottom: 20px;
  }
  .drag-container-link{
    text-decoration: underline !important;
    color: #0d6efd;
    cursor: pointer;
  }