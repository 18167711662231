.multi-select-list {
  background-color: rgba(123, 151, 206, 0.68);
  border-radius: 26px;
  color: #fff;
  padding: 5px;
  white-space: nowrap;
  margin-left: 10px;
}
.multi-select-content {
  .invalid-error {
    width: 100%;
    margin-top: 0.25rem;
    /* font-size: 0.875em; */
    color: #dc3545;
    font-size: 11px;
  }
}

.multi-select-list .close-icon {
  background: transparent;
  border: none;
}
.customInvalid {
  color: #dc3545 !important;
  display: contents;
  font-size: 11px;
}
.input-lable {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.multi-select-content {
  .css-lr9fot-singleValue {
    color: #555555 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}
