.sidebar {
  width: 255px;
  height: calc(100% - 70px);
  overflow: hidden;
  outline: none;
  position: fixed;
  z-index: 1;
  overflow-y: auto;
  left: 0;
  background: #fff;
.p-15{
  padding: 15px !important;
}
.icons{
  width:15px;
  height: 15px;
}
  .notification{
    position: relative;
  .notification-count{
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    right: -25px;
    top: 2px;
    background-color: #DD2025;
  }
  }

  .sidebar-item-list {
    align-items: center;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      a {
        width: 100%;
        color: #636363;
        text-decoration: none;
        font-weight: 500;
        display: flex;
        transition: all 0.2s ease-in-out;
        padding: 15px 15px;
        font-size: 15px;
        margin-bottom: 3px;

        &.active {
          color: #ffffff;
          text-decoration: none;
          background: #015169;
          font-weight: 700;
        }
        &:hover {
          color: #ffffff;
          text-decoration: none;
          background: #015169;
        }
        div {
          &:first-child {
            margin-right: 15px;
          }
        }
        img {
          width: 16px;
          height: auto;
        }
      }
    }
  }
}
.sidebar-icons-accordian{
  width:25px !important;
  height: 30px !important;
  margin-right:10px;
  margin-left:10px;
}
.sidebar-icons{
  width: 30px !important;
  margin-right: -7px;
  margin-left: -8px;
  

}
.sidebar-icons-nested{
  width:20px !important;
  height: 20px !important;
  margin-right:10px;
  margin-left:6px;
}

@media (max-width: 900px){
  .sidebar{
    width: 0px;
  }
  .mobileSide{
    width: 255px;
    height: calc(100% - 70px);
  overflow: hidden;
  outline: none;
  position: fixed;
  z-index: 1;
  overflow-y: auto;
  left: 0;
  background: #fff;
  }
}