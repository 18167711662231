.notification-container-form{

    .form-group {
        margin-bottom: 20px;
        
        position: relative;
      
        input.form-control {
          height: 40px;
          color: #555555;
          font-size: 16px;
          font-weight: 500;
          &.lg {
            height: 50px;
          }
        }
        .invalid-feedback {
          position: absolute;
          bottom: -19px;
          left: 0;
          font-size: 11px;
        }

        .form-check-input {
          width: 1rem !important;
          height: 1rem !important;
        }
      }
    
      .select-group{
        .form-select{
            height: 40px;
        }
    }
    .datePickerBox_Cs {
        
        input[type="text"] {
        min-width: auto;
        max-width: 300px;
        border: 1px solid #ced4da;
        outline: none;
        padding: 0.375rem 0.75rem;
        height: 40px;
      }
      input[type="time"] {
        min-width: 100px;
      }
    }

    .table-btn{
      border: none !important;
      &:hover{
        border: none !important;
      }
    }



}





  
  
  