$dashBoard-card-bg: rgba(127, 162, 184, 0.24);

.icons-size {
  font-size: 22px;
}
.img-slick {
  margin: 20px;
  border-radius: 10px;
}
.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: rgb(0 0 0 / 30%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.IoIosArrowForwardIcon {
  background-color: aqua !important;
  z-index: 2 !important;
  position: relative !important;
}
.carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.carousel .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom : 0px !important;
  background: rgba(0, 0, 0, 0.39) !important;
  height: 100px;
  display: flex !important;
    flex-wrap: nowrap;
}
.custom-indicator li {
  width: 100px !important;
  height: 50px;
  }
.slick-dots li {
  position: relative;
  top: 10px;
}
.property-Name {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 10px;
}
.property-text {
  font-weight: 400;
  color: black !important;
  font-size: 24px;
}
.location-text {
  color: black !important;
  font-size: 16px !important;
  margin-top: 10px;
  width: 290px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.develop-text {
  background-color: #c5aa46;
  color: #FFFFFF;
  padding: 0px 32px;
  font-weight: 500;
  border-radius: 18px;
}
.broker-tag{
  background-color: #0b99a7 !important;
  color: #FFFFFF;
  padding: 0px 32px;
  font-weight: 500;
  border-radius: 18px;
}
.viewmap {
  /* border: 1px solid var(--border-blue); */
  background-color: #fff !important;
  color: #034168 !important;
  font-weight: 600;
  padding: 5px 25px;
}
.viewmap:hover {
  background-color: none !important;
  color: #034168;
}

.map-icon {
  position: relative;
  // top: 35px;
  // left: 10px;
  top:0px;
  color: #034168 !important;
  left: 20px;
}
.bg-light-card {
  background-color: $dashBoard-card-bg;
  border-radius:5px;
}
.developer-details {
  display: flex;
  justify-content: flex-start;
  gap: 35px;
  width: 100%;
  color: #000;
}
.developer-details_card {
  width: 16%;
}

.developer-details_cardText {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.developer-details_cardSubtext {
  font-style: normal;
  font-size: 14px;
  overflow-wrap: "anywhere";
}
.describe-subtext {
  color: var(--black-text);
}
.describe-text {
  color: #3e4958;
  font-weight: 700;
  font-size: 20px;
}

.Property-Details-text {
  color: var(--black-text);
  font-size: 20px;
}
.property-cost {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 10px;
  //margin: 0px 20px 0px 25px;
  // height: fit-content;
  width: 50%;
}
.property-docs {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 10px;
  //margin: 0px 25px 0px 0px;
  width: 50%;
}
.eye-icon {
  font-size: 20px;
  cursor: pointer;
}
.Ameneties {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 20px;
  // margin: 15px 20px 0px 20px;
}
.Ameneties-footer {
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
  padding: 25px;
  background-color: #FFF;
  text-align: center;
  margin-bottom: -20px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer-left{
    text-align: left;
    p{
      margin-bottom: 0px;
    }
    .name-of-property{
      font-size: 24px;
      color: #000000;
    }
    .developer-name{
      font-size: 16px;
      color: #000000;
    }
  }
}
.View-Responses-color {
  background-color: #034168 !important;
  color: #fff;
  font-weight: 600;
  padding: 5px 25px;
}
.Ameneties-locates {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 20px;
  margin: 15px 20px 0px 20px;
}
.property-costed {
  display: flex;
  column-gap: 20px;
}
@media only screen and (max-width: 1200px) {
  .imagelist-hide {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .imagelist-hide {
    display: none;
  }
  .property-hidden {
    display: flex;
  }

  .develop-text {
    display: flex;
    justify-content: center;
  }
  .developer-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .developer-details_card {
    width: 100%;
  }
 
  .property-cost {
    margin: 0px;
  }
  .property-docs {
    margin: 20px 50px 0px 0px !important;
  }
}

@media only screen and (max-width: 600px) {
  .imagelist-hide {
    display: none;
  }
  .property-hidden {
    display: flex;
    flex-direction: column;
  }
  .property-info {
    display: flex;
  }
  .develop-text {
    display: flex;
    justify-content: center;
  }
  .developer-details {
    display: flex;
    flex-direction: column;
  }
  .developer-details_card {
    width: 100%;
  }
  .property-costed {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
  .property-cost {
    margin: 0px;
  }
  .property-docs {
    margin: 20px 50px 0px 0px !important;
  }
}
@media only screen and (max-width: 400px) {
  .imagelist-hide {
    display: none;
  }
  .property-hidden {
    display: flex;
    flex-direction: column;
  }
  .property-info {
    display: flex;
    flex-direction: column;
  }
  .develop-text {
    display: flex;
    justify-content: center;
  }
  .developer-details {
    display: flex;
    flex-direction: column;
  }
  .developer-details_card {
    width: 100%;
  }
  .property-costed {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
  }
  .property-cost {
    margin: 0px;
  }
  .property-docs {
    margin: 20px 50px 0px 0px !important;
  }
  .Ameneties-footer {
    display: flex;
    flex-direction: column;
  }
}

.image-counter {
  position: absolute !important;
  bottom: 100px;
  padding: 0px 20px;
  background: rgba(0, 0, 0, 0.81);
  border-radius: 22px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 900px) {
  .mobile{
    margin-top: 10px;
    .viewmap{
      width: 100%;
    }
    .map-icon{
      top:30px;
    }
  }
}
.noimageWrapper{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}