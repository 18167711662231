.dropZone-ImageSize-clientele {
  position: relative;
}
.images-dropzone-clientele {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 2px 5px;
}
.remove-image-clientele {
  color: #dd2025;
  width: fit-content;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 1px 4px;
  cursor: pointer;
}
.image-dropzoneWrapper-clientele {
  display: flex;
  flex-wrap: wrap;
}

.pre-btn-clientele {
  display: block;
  width: 120px;
  // height: 40px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  color: rgba(21, 25, 32, 0.5) !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  border: 1px solid rgba(86, 103, 137, 0.26) !important;
  background: #ffffff !important;

  &:hover {
    color: rgba(21, 25, 32, 0.5) !important;
    background: #ffffff !important;
  }
}

.next-btn-clientele {
  display: block;
  width: 120px;
  // height: 40px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  border-radius: 6px !important;
  font-weight: 600 !important;

  background: #015169 !important;
  color: white !important;

  &:hover {
    color: #ffffff !important;
    background: #015169 !important;
  }
}

.clientele-logo-img {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
}
