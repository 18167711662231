.card {
    transition: 0.3s;
    height: 286px !important;
    border-radius: 10px;

    .card-body {
        display: flex;
        row-gap: 35px;
        flex-direction: column;

        .card-head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .card-head-image {
                background: #ebf0f4;
                border-radius: 3px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 25px;
                    height: 28px;
                }
            }

            .card-head-left {
                display: flex;
                align-items: center;
                column-gap: 10px;

                .card-title {
                    font-size: 24px;
                    color: #015169;
                    font-weight: 500;
                    margin-bottom: 0px;
                }
            }

            .card-head-right-content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 94px;
                height: 49px;
                font-size: 24px;
                font-weight: 500;
                color: #015169;
                background-color: #ebf0f4;
                border-radius: 5px;
            }
        }

        .card-details {
            grid-template-columns: repeat(4, 1fr);
            display: grid;
            // gap: 20px;
            margin-top: 0;

            .border-card {
                border-right: 1px solid #c4c4c4;
                border-left: 1px solid #c4c4c4;
            }

            .border-card-rm {
                border-right: 1px solid #c4c4c4;
                // border-left: 1px solid #c4c4c4;
            }

            .card-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                text-align: center;

                .card-data {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center;
                }
                .data-wrapper {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                    justify-content: space-evenly;
                    text-align: center; 
                }

                .sub-title {
                    font-size: 14px;
                    font-weight: 700;
                    width: 80%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .card-content-count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 94px;
                    height: 49px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #015169;
                    border-radius: 3.62694px;
                    background-color: #ffffff;
                    border: 0.725389px solid #ebf0f4;
                    position: relative;
                }

                .btn-view {
                    color: #015169;
                    background-color: #ffffff;
                    border: 0.73px solid #015169;
                    border-radius: 4px;
                    margin: 15px 0px;
                }
            }
        }
    }
}

.card-count-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    // width: 94px;
    // height: 55px;
    // font-size: 24px;
    // font-weight: 500;
    // color: #015169;
    border-radius: 3.62694px;
    background-color: #ffffff;
    border: 1px solid #ebf0f4;
    position: relative;
}

.card-subtext {
    font-size: 15px;
    font-weight: 600;
    // margin: 15px 0px;
    // width: 80%;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
}

.total-count {
    color: #015169;
    font-size: 24px;
    font-weight: 500;
}

.count-text {
    color: #015169;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
}

.btn-view {
    color: #015169;
    background-color: #ffffff;
    border: 0.73px solid #015169;
    border-radius: 4px;
    font-size: 15px;
}

.btn-new {
    color: #dd2025;
    background-color: #ffffff;
    border: 0.73px solid #dd2025;
    border-radius: 4px;
    font-size: 15px;
}

// .btn-new:hover {
//     color: #dd2025;
//     background-color: #ffffff;
//     // border: 0.73px solid #dd2025;
//     // cursor: pointer;
//     border-radius: 4px;
//     font-size: 15px;
// }



// @media screen and (max-width: 1280px) {
//   .dashboard-card-container {
//     .card {
//       .card-body {
//         .card-details {
//           .sub-title {
//             width: 70px;
//            white-space: nowrap;
//            text-overflow: ellipsis;
//            overflow: hidden;
//           }
//         }
//       }
//     }
//   }
// }


// -------------