.tab-containered {
  background: #ffffff;
  border-radius: 8px;
  .react-bootstrap-table {
    border-radius: 0px;
  }
}

.tab-headings {
  background-color: #7fa2b8;
  color: #ffffff;
  padding: 4px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.tab-containered .nav-item {
  border: none !important;
  padding-bottom: 1px;
}

.tab-containered .nav-tabs .nav-link {
  border: none !important;
}

//   .tab-containered .nav-tabs .nav-link.active {
//     border: none !important;
//   }

.tab-containered .nav-tabs .nav-link button:focus.a:focus {
  border: none !important;
  outline: none !important;
}

.tab-containered .nav-tabs .nav-link {
  color: var(--primary-text) !important;
  background: transparent;
  outline: none !important;
  border-color: transparent;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
}

.tab-containered .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--primary-active-color) !important;
  outline: none !important;
  background: #ffffff !important;
  border-color: transparent;
}

.tab-containered .nav-tabs {
  background-color: transparent !important;
  border-bottom: 1px solid #e7e3e3 !important;
  outline: none !important;
}

.tab-containered .nav-tabs .nav-item.show .nav-link,
.nav-tabs {
  color: #c4c4c4 !important;
  background: transparent;
  outline: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #015169 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #c4c4c4 !important;
}

.tab-containered .nav-tabs {
  border-bottom: 0;
}

.tab-containered .nav-tabs .nav-link {
  position: relative;
  padding: 16px 16px;
}

.tab-containered.nav-tabs .nav-link:focus,
.tab-containered .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.tab-containered .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--primary);
  bottom: -4px;
  max-width: 40%;
  min-width: 25px;
  border-radius: var(--border-radius-default);
  margin-bottom: 1px;
}

.tab-containered.tabBody {
  margin-top: 10px;
  margin-bottom: -10px !important;
}
