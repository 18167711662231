.progress .success{
    background-color: green;
}
.success{
    background-color: #ffffff;
    color: #000000;
}
.progress .error{
    background-color: red;
}
.error{
    background-color: #ffffff;
    color: #000000;
}