.select-group {
  .form-select {
    height: 40px;
  }
}
.marginWrapReport {
  // margin-top: 15px;
}
.marginWrapBar{
    margin-top:1%;
}