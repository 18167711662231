
.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}
@media (max-width:800px){
  .map-card{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
    min-height: 153px;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
    max-width: 443px;
    position: absolute;
    background-color: #ffffff;
    top: 147px;
    z-index: 420;
    opacity: 1;
    left: 50%;
    transform: translate(-50%, 0);
    min-width:200px;
    .map-accordion-data-container{
      height: 100px;
      overflow: auto;
      cursor: pointer;
      .map-card-accordion-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        padding: 10px 10px;
       
      }
      .map-card-accordion-content:hover{
        background-color: rgba(127, 162, 184, 0.16) !important ;
      }
    }
    @media (max-width:900px) {
      top:50px;
      
    }
  }
}
.map-card{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px;
  min-height: 153px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
  max-width: 443px;
  position: absolute;
  background-color: #ffffff;
  top: 40px;
  z-index: 420;
  opacity: 1;
  left: 50%;
  transform: translate(-50%, 0);
  min-width:200px;
  .map-accordion-data-container{
    height: 100px;
    overflow: auto;
    cursor: pointer;
    .map-card-accordion-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      vertical-align: middle;
      padding: 10px 10px;
     
    }
    .map-card-accordion-content:hover{
      background-color: rgba(127, 162, 184, 0.16) !important ;
    }
  }
  @media (max-width:900px) {
    top:50px;
    
  }
}
.map-close{
  top: 200px;
  @media (max-width:900px) {
    top:150px;
    
  }
}