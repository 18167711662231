.company-radio-container{
    column-gap: 15px;
    .form-check-inline {
        display: flex;
        flex-direction: row;
        column-gap: 7px;
        margin: 0;
    }

    
    .form-check .form-check-input {
        width: 20px !important;
        height: 20px !important;
    }
    
}