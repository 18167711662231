$progress-active-bg: #034168;
$progress-sucess-bg: #20c374;
$progress-inactive-bg: #e5e5e5;
$progress-white-bg: #ffffff;
.office-space-continer{
  max-width:1000px;
  margin: 0 auto;
}
.bg-completed {
    background-color: $progress-sucess-bg;
    color: #ffffff;
  }
  .bg-inactive {
    background-color: $progress-inactive-bg;
  }
  .bg-active {
    background-color: $progress-active-bg !important;
    color: #ffffff;
  }
.stepForm .step-count {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    /* background-color: var(--progress-active-bg) ; */
    // color: var(--white-text);
    text-align: center;
    // background: $progress-inactive-bg;
  }
  
  .stepForm .line {
    width: 80%;
  }
  
  .stepForm .progress {
    background-color: #bdbdbd;
    width: 70%;
    height: 2px;
  }
  
  .stepForm .progress .active-bar {
    background: #034168;
    width: 70%;
    height: 2px;
  }
  
  .stepForm .active {
    /* background-color: #20C374; */
    height: 2px;
    /* min-width:10px; */
    width: 50%;
    background: linear-gradient(to right, #034168 50%, #bdbdbd 50%);
  }
  
  .stepForm .success {
    /* background-color: #20C374; */
    height: 2px;
    /* min-width:10px; */
    width: 50%;
    background: $progress-sucess-bg;
  }
  
  .stepForm .inactive {
    /* background-color: #20C374; */
    height: 2px;
    /* min-width:10px; */
    width: 50%;
    background: $progress-inactive-bg;
  }
  
  .stepForm .heading {
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 12px;
    /* identical to box height */
  
    letter-spacing: 0.0025em;
  
    color: #888888;
  }
  
  .stepForm .sub-text {
    font-weight: 500;
    font-size: 0.8rem;
    color: #000;
    letter-spacing: 0.001em;
    max-width: 90%;
  }
